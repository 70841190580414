import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useWallet } from '../../context/WalletContext';
import { formatAddress } from '../../shared';

interface MenuItem {
    text: string;
    href: string;
}

export const Navbar: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isConnected, connectWallet, disconnectWallet, account } = useWallet();

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems: MenuItem[] = [
        { text: 'Marketplace', href: '/' },
        { text: 'Bridge', href: '/bridge' },
        { text: 'Tutorial Page', href: '/tutorial-page' },
        { text: 'Create Listing', href: '/create-listing' },
        { text: 'Connect', href: '#' },
    ];

    const list = () => (
        <Box
            sx={{ width: 250, height: '100vh' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className='drawer'
        >
            <List>
                {menuItems.slice(0, -1).map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ 'fontFamily': 'sans-serif, roboto' }}>
                        <Button
                            key={index}
                            component={RouterLink}
                            to={item.href}
                            color="secondary"
                            sx={{ mx: 1 }}
                            className='nav-item'
                        >
                            {item.text}
                        </Button>
                    </ListItem>
                ))}
                <hr className='hr mx-1 my-2' />
                <ListItem key={-1} disablePadding>
                    {isConnected
                        ?
                        <Button color="secondary" sx={{ mx: 1 }} className='nav-item'>{formatAddress(account ?? "")}</Button>
                        :
                        <Button color="secondary" sx={{ mx: 1 }} className='nav-item' onClick={() => connectWallet()}>{menuItems[menuItems.length - 1].text}</Button>
                    }
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <div className="navbar-wrapper">
                <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <Toolbar>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                            {menuItems.slice(0, -1).map((item, index) => (
                                <Button
                                    key={index}
                                    component={RouterLink}
                                    to={item.href}
                                    color="secondary"
                                    sx={{ mx: 1 }}
                                    className='nav-item'
                                >
                                    {item.text}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            {isConnected
                                ?
                                <Button color="secondary" className='nav-item'>{formatAddress(account ?? "")}</Button>
                                :
                                <Button color="secondary" className='nav-item' onClick={() => connectWallet()}>{menuItems[menuItems.length - 1].text}</Button>
                            }
                        </Box>
                        <IconButton
                            edge="start"
                            color="secondary"
                            aria-label="menu"
                            sx={{ display: { xs: 'block', md: 'none' } }}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            </div>
        </>
    );
};
