import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import React from 'react';
import { Listing } from '../../types';
import { useLoader } from '../../context/LoaderContext';
import useNotification from '../../utilities/notificationUtils';
import * as ethers from 'ethers';
import { getTokenSmartContract, getSwapSmartContract, formatAddress } from '../../shared';
import { useWallet } from '../../context/WalletContext';
import { handleMetaMaskError } from '../../utilities/handleMetaMaskError';
import { getChain, getTokenNameFromAddress, chains } from '../../config';
import { useListing } from '../../context/ListingContext';

type ListingCardProps = {
    listing: Listing;
    onRemoved: () => void;
};

export const ListingCard: React.FC<ListingCardProps> = ({ listing, onRemoved }) => {
    const { showLoader, hideLoader } = useLoader();
    const { showSuccess, showInfo, showError } = useNotification();
    const { isConnected, account, network } = useWallet();
    const { showEmoji } = useListing();

    const fromToken = getTokenNameFromAddress(listing.coinForSale);
    const toToken = getTokenNameFromAddress(listing.coinToReceive);

    const fromChain = getChain(fromToken?.chainId ?? 0);
    const toChain = getChain(toToken?.chainId ?? 0);

    const handleClaimListing = async () => {
        if (!isConnected) {
            showError("Please connect your wallet to proceed");
            return;
        }
        if (!listing) return;

        showLoader();
        try {
            showInfo("Approve the transaction to continue");

            const tokenContract = await getTokenSmartContract(listing.coinToReceive);
            const amountToReceive = ethers.parseUnits(listing.amountToReceive.toString(), toToken?.decimals);
            const selectedSmartContract = chains.find(x => x.chainId === network)?.address ?? "";
            const txApprove = await tokenContract.approve(selectedSmartContract, amountToReceive);
            console.log('Approval transaction hash:', txApprove.hash);
            await txApprove.wait();
            showSuccess("Transaction approved. Proceeding to claim the listing.");

            const contract = await getSwapSmartContract(network);
            const txClaim = await contract.claimListing(listing.listingId);
            console.log('Claim transaction hash:', txClaim.hash);
            await txClaim.wait();
            showSuccess("Listing claimed successfully");
            onRemoved();
            showEmoji();
        } catch (error: any) {
            console.error('Error claiming listing:', error);
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    const handleCloseListing = async () => {
        if (!isConnected) {
            showError("Please connect your wallet to proceed");
            return;
        }
        if (!listing) return;

        showLoader();
        try {
            showInfo("Approve the transaction to continue");
            const contract = await getSwapSmartContract(network);
            const txClaim = await contract.closeListing(listing.listingId);
            console.log('Claim transaction hash:', txClaim.hash);
            await txClaim.wait();
            showSuccess("Listing closed successfully");
            onRemoved();
            showEmoji();
        } catch (error: any) {
            console.error('Error claiming listing:', error);
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    return (
        <Card className='listing-card' sx={{ boxShadow: 1, height: '100%' }}>
            <CardContent>
                <Typography variant="h6" component="div" sx={{ mb: 2, fontWeight: 600 }}>
                    {listing.isCrossChain ? "P2P bridge listing" : <span></span>}
                </Typography>
                <Typography variant="caption" component="div" sx={{ mb: 2, fontWeight: 600 }}>
                    Exchanging
                </Typography>
                <div className='mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                    {fromToken?.logoURI ? (
                        <img src={fromToken?.logoURI} alt={fromToken.symbol} style={{ width: 24, height: 24, marginRight: 8 }} />
                    ) : (
                        <div style={{ width: 24, height: 24, borderRadius: '50%', backgroundColor: '#ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8, fontSize: 16, color: '#fff', fontWeight: 'bold' }}>
                            {fromToken?.symbol.substring(0, 3)}
                        </div>
                    )}
                    <Typography variant="body2" component="div">
                        {fromToken?.name} ({fromToken?.symbol}) <TrendingFlatIcon /> {listing.amountForSale}
                    </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {fromChain?.logoURI ? (
                            <img src={fromChain?.logoURI} alt={fromChain.name} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                        ) : null}
                        <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                            {formatAddress(fromToken?.address ?? "", 10)}
                        </Typography>
                    </div>
                </div>
                <Typography variant="caption" component="div" sx={{ mb: 2, fontWeight: 600 }}>
                    Asking price
                </Typography>
                <div className='mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                    {toToken?.logoURI ? (
                        <img src={toToken?.logoURI} alt={toToken.symbol} style={{ width: 24, height: 24, marginRight: 8 }} />
                    ) : (
                        <div style={{ width: 50, height: 25, borderRadius: '50%', backgroundColor: '#ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8, fontSize: 8, color: '#fff', fontWeight: 'bold' }}>
                            {toToken?.symbol.substring(0, 1)}
                        </div>
                    )}
                    <Typography variant="body2" component="div">
                        {toToken?.name} ({toToken?.symbol}) <TrendingFlatIcon />  {listing.amountToReceive}
                    </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {toChain?.logoURI ? (
                            <img src={toChain?.logoURI} alt={toChain.name} style={{ width: 24, height: 24, borderRadius: '50%' }} />
                        ) : null}
                        <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                            {formatAddress(toToken?.address ?? "", 10)}
                        </Typography>
                    </div>
                </div>
            </CardContent>
            <CardActions>
                {
                    listing.seller.toLowerCase() === account?.toLowerCase()
                        ? (
                            <Button variant="contained" color="primary" onClick={handleCloseListing} endIcon={<TrendingFlatIcon />}>
                                Close Listing
                            </Button>
                        )
                        : (
                            listing.isCrossChain
                                ? (
                                    listing.destinationChainId === network
                                        ? (
                                            <Button variant="contained" color="primary" onClick={handleClaimListing} endIcon={<TrendingFlatIcon />}>
                                                Buy Now
                                            </Button>
                                        )
                                        : (
                                            <Button variant="contained" color="secondary" disabled>
                                                Incompatible Network
                                            </Button>
                                        )
                                )
                                : (
                                    <Button variant="contained" color="primary" onClick={handleClaimListing} endIcon={<TrendingFlatIcon />}>
                                        Buy Now
                                    </Button>
                                )
                        )
                }
            </CardActions>
        </Card >
    );
};
