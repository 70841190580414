import { TokenAddress } from "../types";

const tokenAddresses: TokenAddress[] = [
    // Testnet
    {
        "address": "0x73a3b4e55472cc79508fefddced3564766de2601",
        "name": "MYCOIN1",
        "symbol": "MYCOIN1",
        "decimals": 18,
        "chainId": 11155111,
        "logoURI": null
    },
    {
        "address": "0x804f3dfd6f9e386e7ad898ccc40b714530ce8011",
        "name": "MYCOIN2",
        "symbol": "MYCOIN2",
        "decimals": 18,
        "chainId": 11155111,
        "logoURI": "https://token-media.defined.fi/1996_0x13d675bc5e659b11cfa331594cf35a20815dcf02_large_d074e70e-ca82-4367-ae69-51ef58786e08.png"
    },
    {
        "address": "0x0e91f84c1cb7abef54734b6ac4f132cc653dc4e2",
        "name": "TC",
        "symbol": "TC",
        "decimals": 18,
        "chainId": 11155111,
        "logoURI": null
    },
    {
        "address": "0xfa9918467b587891aaf17563a03a23d098be1c05",
        "name": "TC",
        "symbol": "TC",
        "decimals": 18,
        "chainId": 17000,
        "logoURI": null
    },
    // Production
    {
        "address": "0x13D675BC5e659b11CFA331594cF35A20815dCF02",
        "name": "USD Coin",
        "symbol": "USDC",
        "decimals": 6,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x13d675bc5e659b11cfa331594cf35a20815dcf02_large_d074e70e-ca82-4367-ae69-51ef58786e08.png"
    },
    {
        "address": "0x754cDAd6f5821077d6915004Be2cE05f93d176f8",
        "name": "Wrapped DMT",
        "symbol": "wDMT",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x754cdad6f5821077d6915004be2ce05f93d176f8_large_04bfd83a-db80-4956-a60d-0a4fa4316439.png"
    },
    {
        "address": "0xE01e3b20C5819cf919F7f1a2b4C18bBfd222F376",
        "name": "Wrapped ETH",
        "symbol": "wETH",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0xe01e3b20c5819cf919f7f1a2b4c18bbfd222f376_large_925ecb71-f636-4604-b7f0-4153ce2cd136.png"
    },
    {
        "address": "0x9c2730eA529c70E901fbDaf3e15E110CA8701C61",
        "name": "stanko",
        "symbol": "STANKO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x9c2730eA529c70E901fbDaf3e15E110CA8701C61.png"
    },
    {
        "address": "0x3C84f959f4b8ca0c39847d02f936e13Fa8fC4Eb9",
        "name": "Beat The Allegations",
        "symbol": "STAR",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x3C84f959f4b8ca0c39847d02f936e13Fa8fC4Eb9.png"
    },
    {
        "address": "0x3c02f27100a06287e55bBA1A588CdC22D72B7965",
        "name": "LSD",
        "symbol": "LSD",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x3c02f27100a06287e55bBA1A588CdC22D72B7965.png"
    },
    {
        "address": "0x8D18F820d0bD4cE93550EcB9a69763E585713E1d",
        "name": "WONYCOIN",
        "symbol": "WONY",
        "decimals": 6,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x8D18F820d0bD4cE93550EcB9a69763E585713E1d.png"
    },
    {
        "address": "0x619Ef22392A549C9638D170db8C985fD147fAC98",
        "name": "EUGENE",
        "symbol": "EUGENE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x619Ef22392A549C9638D170db8C985fD147fAC98.png"
    },
    {
        "address": "0xe78A3857593083dD6e1F7859e1B711401FC6f2cc",
        "name": "smoke the ticker",
        "symbol": "SMOKE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0xe78a3857593083dd6e1f7859e1b711401fc6f2cc_large_eeb9fd61-9db7-4913-9487-3b82ac8883fb.png"
    },
    {
        "address": "0x004B955e26bA98685b19188ec5eEDD809D6F6A89",
        "name": "Sankooop",
        "symbol": "OOP",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x6F5e2d3b8c5C5c5F9bcB4adCF40b13308e688D4D",
        "name": "Sanko Gold",
        "symbol": "GOLD",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x6f5e2d3b8c5c5c5f9bcb4adcf40b13308e688d4d_large_615f29f2-319a-4992-a07a-b0de3bdcf4f8.png"
    },
    {
        "address": "0x9F8A8BcD61201E9016AC40D0396632798B379D42",
        "name": "Stonk Eternal Rising Sun Token",
        "symbol": "STONK",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xCBcd7944c0E729dDF44A8Fed973D74BD37Af9170",
        "name": "Trippy Dimethyltryptamine",
        "symbol": "TRIPPY",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0xCBcd7944c0E729dDF44A8Fed973D74BD37Af9170.png"
    },
    {
        "address": "0x17b46ba1bf7132eF0d38923DBDB63a5D4CC671a4",
        "name": "Sweet Baby Ray's",
        "symbol": "RAYS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xD2A35b0EA0e99b9f8cA247C86B08De35Ee5D3Bf6",
        "name": "xDMT",
        "symbol": "xDMT",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": null
    },
    {
        "address": "0x80e53DC303782d6CC1624d56b4C3bDa4125DB158",
        "name": "POOP",
        "symbol": "SRANKO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x80e53DC303782d6CC1624d56b4C3bDa4125DB158.png"
    },
    {
        "address": "0x7c6f762130A92bee0561e9266864478BE60b649F",
        "name": "Ohio Coin",
        "symbol": "Ohio",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x7c6f762130A92bee0561e9266864478BE60b649F.png"
    },
    {
        "address": "0xb7fE17822bD3298f3b5F7Dd3e1827a47fbb94B60",
        "name": "Pineapple Pizza",
        "symbol": "PP",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x4D1a33b45Fac0dF46BA5a1E38746d9CC5c7B2aD6",
        "name": "Base Pro Shops",
        "symbol": "BPS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xad89dCFE247D12136Bc66127d70fFAb476136eCB",
        "name": "DMTardio Token",
        "symbol": "TARD",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0xad89dcfe247d12136bc66127d70ffab476136ecb_large_fa9bc12d-13c4-4395-a251-c4cedf6755f0.png"
    },
    {
        "address": "0x80890e2F5668ab084657126A2eB2bf4324fAFEB1",
        "name": "Well Endowed Enormous Dongs",
        "symbol": "WEED",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x7c1bF215B158ae5566d7E78Fd46FFC09Dac3A898",
        "name": "Coke",
        "symbol": "COKE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xAE55b9912b78334AfC8a2fb8E00F449123c52989",
        "name": "OsamaWaifu",
        "symbol": "OSAMA",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0xAE55b9912b78334AfC8a2fb8E00F449123c52989.png"
    },
    {
        "address": "0x1f1ead14FeF3B6fF6559D38f4Bda7AB1207C28A0",
        "name": "Psilocybin",
        "symbol": "PSILOCYBIN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x795cE8Ae1EC670b008437d66D0aD93c29db61fe0",
        "name": "bunwifhat",
        "symbol": "bunwifhat",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xaB411EF6aC0cC46d0781897087206BcC43aB6925",
        "name": "Thesis",
        "symbol": "Thesis",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x2180C773Aa7D19E1F31C99E9e7797ff3cf77F28f",
        "name": "Tryppy",
        "symbol": "Tryppy",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x39B2F2e3Efc03a51cD20Fe452D0d1B8C406a00c7",
        "name": "Brazen Unchecked Narcissism",
        "symbol": "BUN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x03449bC5981433B63817323b16F1057AB102EF21",
        "name": "look at him go",
        "symbol": "HIMGO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x03449bC5981433B63817323b16F1057AB102EF21.png"
    },
    {
        "address": "0x86c58A4Fe17A37dd8A80B27ffeF5a3Fad342e2E8",
        "name": "Ketamine",
        "symbol": "KET",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x86c58a4fe17a37dd8a80b27ffef5a3fad342e2e8_large_b7f190b7-d055-4b06-874b-f39572572595.png"
    },
    {
        "address": "0x8d6b896Bfa4589e52f9bA1DdF9A81A749EFD253F",
        "name": "HelloKittyTequilaGirlGriffithInu",
        "symbol": "SOLANA",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xA7CAcFD5a119196AA87e6159cd385A0ca9d615AE",
        "name": "Joe Rogan",
        "symbol": "JRE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x1aDEFF4A6fCf6aE600BfD4604B70885c0588DADf",
        "name": "Beta-Carboline",
        "symbol": "β-Carboline",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x8FaF0B7A8B1bdC1103C185eE73b88e3638E75765",
        "name": "Lazy Ethiopian Ass Nigga",
        "symbol": "LEAN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xeA240b96A9621e67159c59941B9d588eb290ef09",
        "name": "MOSS",
        "symbol": "MOSS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x5134c6bA61d3dEe4a7e690Ce98D9d217054C33Fd",
        "name": "SaddamWaifu",
        "symbol": "SADDAM",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x8fB929Cd6448DbFE498E1FB0eAc79d5DdbB4cde1",
        "name": "sankyu",
        "symbol": "sankyu",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x59f1C172AB13E9Ac988aDd35b1e3981BFE387b51",
        "name": "JENKEM",
        "symbol": "JENKEM",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x59f1c172ab13e9ac988add35b1e3981bfe387b51_large_fd589fda-b3ba-4816-afff-0d519c193dd1.png"
    },
    {
        "address": "0xBc4fDeA1Aa3C3A02357f3D95016279a10f29a516",
        "name": "DesertEagle",
        "symbol": "DEAGLE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0xBc4fDeA1Aa3C3A02357f3D95016279a10f29a516.png"
    },
    {
        "address": "0x329EF41d2D78F86f5BeBFD05dEd01e73412ceAa5",
        "name": "Gorli",
        "symbol": "GORLI",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x329EF41d2D78F86f5BeBFD05dEd01e73412ceAa5.png"
    },
    {
        "address": "0xC331FD9f45bE388F28B357202302179e04E8e2ed",
        "name": "DMT Turkish Takeover",
        "symbol": "KEBAB",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xdB3b5C0f8C716500864ED491c2163CbBE2329C26",
        "name": "JUANPINGU",
        "symbol": "JUAN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0xdB3b5C0f8C716500864ED491c2163CbBE2329C26.png"
    },
    {
        "address": "0xb3aCfdFc1C3227996C44a6D6D0b748330D8B9CC8",
        "name": "Sankyuu",
        "symbol": "SANKYUU",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": null
    },
    {
        "address": "0x8c66E7b1499c2Bc81DbA631b6d2309A401038A7f",
        "name": "KEVIN",
        "symbol": "KEV",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x23e45bB001EDa33f95e22c45947D85f5416a0433",
        "name": "JUAN SHORT SYNTHETIC POSITION",
        "symbol": "notJUAN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x02A20470fd18C11a43BdB364B907dB6937eC9D92",
        "name": "Deeply Melanated Terror",
        "symbol": "NIGGER",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x0371844C8C88904CEc6dfC582c5E31aa4230c792",
        "name": "Federal Bureau of Investigation",
        "symbol": "FBI",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x4D94ef1009B16829B87E557e371fd1D37d1a9645",
        "name": "Dont be stupid",
        "symbol": "DBS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x97dF2fa24e5abf8fAD18A81603ABD1cf4D077967",
        "name": "Pedro The Racoon",
        "symbol": "PEDRO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x6b12eD65B31f78f50d2B1fB401523c9aBcFAb78b",
        "name": "GLIZZY",
        "symbol": "GLIZZY",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x3f710cBd0F4268719C6c2E5E078a4CAfAeED7d45",
        "name": "Smoovie Phone",
        "symbol": "SP",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x3f710cbd0f4268719c6c2e5e078a4cafaeed7d45_large_b60b03ff-de4b-4ad4-ade5-030a8c17ac6d.png"
    },
    {
        "address": "0x5d4041d56e968Dc33a185ED0f1c20E3588387742",
        "name": "microtubules",
        "symbol": "XENON",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x2BFd4b0B6ea0BCD02Ef855b2Ee59dE53dD9c3442",
        "name": "counterstrike map",
        "symbol": "dust2",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://sanko-tokens.s3.amazonaws.com/0x2BFd4b0B6ea0BCD02Ef855b2Ee59dE53dD9c3442.png"
    },
    {
        "address": "0x12592e339167d9831b760Fd3219cA1ca3C493b1f",
        "name": "big tiddy goth gf",
        "symbol": "8008",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xD515f654868D2c601508A490085eA4FeBadFBbbD",
        "name": "raz test",
        "symbol": "raz",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0xd515f654868d2c601508a490085ea4febadfbbbd_large_afeffa5d-3cb3-499f-bec3-7991b6897589.png"
    },
    {
        "address": "0x235D6a3C5cF8517a78DF38B3Dfcd59e37a68C730",
        "name": "Cookie Monster",
        "symbol": "COOKIE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xA148A2895993915e3e8c215F6D1Ac6be27051fBB",
        "name": "Sanko Official Pussy",
        "symbol": "Pussy",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x16129499929b2776F804BD31eeCc992179418DCC",
        "name": "Nigi the Cat",
        "symbol": "Nigi",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x2F5A428D4Dd528EE431Eedd0F36620020Df730bC",
        "name": "Kirtaner Coin",
        "symbol": "KIRTANER",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x17a231B415ceE73C95C7b4FE89764a5Dd158B009",
        "name": "Syphilis",
        "symbol": "syph",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xD12C96a61Fe25fA1Ae018374f668D5151065848D",
        "name": "mr. smoovie williams",
        "symbol": "smoovie",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x2d6663253A4d438C41D66CCAE35051C7F3C8eC7a",
        "name": "THE GOODLUMS",
        "symbol": "GOODLUMS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x674E9148833e42135A9D3eBbff8B2DF101aE8A9B",
        "name": "I made your lifetime net worth on DMT bro why you pocket watching and selectively showing random transactions to make some weird point",
        "symbol": "SankoGCR",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x699bFE71910D14B8560f65D5Bd370FC65d0c3B64",
        "name": "Sanko Silver",
        "symbol": "Silver",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x699bfe71910d14b8560f65d5bd370fc65d0c3b64_large_5d18e28b-33cd-491e-88ff-379707ed75c3.png"
    },
    {
        "address": "0x5912F5A11f0472ecC56FbD7e91E595778Acb71d5",
        "name": "BELLA CIAO",
        "symbol": "BELLA CIAO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x6181b1E1e902CdC566444CcC4Bc21671ad9C1BCA",
        "name": "My Little Milady Maker ",
        "symbol": "MLMM",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x6181b1e1e902cdc566444ccc4bc21671ad9c1bca_large_9e8d0f92-056f-498c-9b70-315490b88a5f.png"
    },
    {
        "address": "0x13637659DecD4fe69fAe0a191A4AE6ff616D7a22",
        "name": "Heavy Petting",
        "symbol": "MOLEST",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x0389e6C3890B6025E3494D2699D966A3Dc9a4c36",
        "name": "Sanko Bros ©",
        "symbol": "BRO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x9cC336A3995D80cFa7D2fF0Df051F7d321d8AE99",
        "name": "Kirtaner Touched Me At Bella Ciao",
        "symbol": "KTMABC",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x03B1Af7bbA9a48e492F32A423cB9958002c876D2",
        "name": "TRUMP",
        "symbol": "TRUMP",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x8dE9702966852cC8d541464933a1368EA79fD368",
        "name": "SANKOSCAPE",
        "symbol": "SCAPE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xCc259a083F8f2Ac3cD5E28DB8E1Eaa069424b545",
        "name": "Kirtaner Rugged",
        "symbol": "KRUG",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x76022247Dd0d85cC8da0AA96259C0420C4a01F76",
        "name": "Cartnite",
        "symbol": "CARTNITE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xE9362EA6Ac0995E202d86F11fa7be417Ec527AA7",
        "name": "smoke the dicker",
        "symbol": "STD",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xb9CAeC661E7F24d09b96179aad80281f7ddBD951",
        "name": "Sanko Vibe ©️",
        "symbol": "VIBE",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0xb9caec661e7f24d09b96179aad80281f7ddbd951_large_5aac3e87-0373-4219-ae9d-a2dd589e7cca.png"
    },
    {
        "address": "0x44E17FC1009270d8B32A645C9D9Ed85Ab25ceD4c",
        "name": "Big Chungus",
        "symbol": "CHUNGUS",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x410AE4Ea0c561990EdF689ded66d7361384966af",
        "name": "To be frank...",
        "symbol": "TBF",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x410ae4ea0c561990edf689ded66d7361384966af_large_e9543747-9680-4b28-94aa-fd38e5e84930.png"
    },
    {
        "address": "0x1f6714500E71A4D94d1A325E3303431817F79aB2",
        "name": "CumALot",
        "symbol": "CUM",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xC400182eA8474B41154B7E60B3AFdCb628759155",
        "name": "LUMBRIDGE",
        "symbol": "LUMB",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x737EcF25a7C71B4Ae8e7f951d140F354B38120B5",
        "name": "ANONYMOUS",
        "symbol": "ANON",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x6E80f80F1FF24ab904f3a946eC4BCbf08846f926",
        "name": "Bunzempic",
        "symbol": "BUNZEMPIC",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x60764C882c83528eA9Ee46f65fe31847D48ebD55",
        "name": "Green Cat",
        "symbol": "GC",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x0b4c4edc4F43f89B035234aC1C26981a868313fF",
        "name": "Chikki Sanko Pet",
        "symbol": "CHIKKI",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x50Ae93eBe9D2cD46e56831a8f59c2e1e9B2D26D9",
        "name": "White Tiger Coin",
        "symbol": "WTC",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x9a3d488652eaE614283b9673993ccaadD6a73BC6",
        "name": "PANGAS PANDEIRO",
        "symbol": "PAN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x4B5b8E16B45e3299461e9B33450495Fc7C7431C6",
        "name": "ZYN TOKEN",
        "symbol": "ZYN",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x6E3dAAe44E7D99B38e8Ddaa176944f759C95E93f",
        "name": "Catboi Malangey",
        "symbol": "MEOW",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x61448353c16A362E34B2CD4dc5b8E38A641B08D3",
        "name": "PUMP THE DICK",
        "symbol": "DICK",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xb96D61a97256966C950db7B4132Fd6f91Bc4eae3",
        "name": "Nintendo 64",
        "symbol": "N64",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xF09893fFf18c33A8dd5F55FB9eDA6C518789b1cA",
        "name": "SPROTO",
        "symbol": "SPROTO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x0B5ED54cFADDfE16ed568030B7e897732B9C2C50",
        "name": "Gosling",
        "symbol": "Gosling",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x0b5ed54cfaddfe16ed568030b7e897732b9c2c50_large_527fe030-93ec-4bbf-8936-10684978de64.png"
    },
    {
        "address": "0x02fd020f1F4212A162281C51864f6F1C5aeA5A28",
        "name": "Bunsanto",
        "symbol": "SANTO",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x02fd020f1f4212a162281c51864f6f1c5aea5a28_large_338bf533-4f6d-4c5b-b4fb-347eacdb749b.png"
    },
    {
        "address": "0x82eb68b0a61e940e2d138264a143a1692b46E43D",
        "name": "Book Of Sanko",
        "symbol": "BOOK",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0xf296F133EF9e9E9050f3fd3E73c6250EC03c7cCF",
        "name": "Small Size Chad",
        "symbol": "SSC",
        "decimals": 18,
        "chainId": 1996,
        "logoURI": ""
    },
    {
        "address": "0x1099058a912aCADB29042f06Ecb40Db6C9BD9091",
        "name": "Unicorn",
        "symbol": "UWU",
        "decimals": 6,
        "chainId": 1996,
        "logoURI": "https://token-media.defined.fi/1996_0x1099058a912acadb29042f06ecb40db6c9bd9091_large_86c609fb-db21-42dc-b491-c98ce437c23f.png"
    },
    {
        "address": "0xb74a6bd9e68c12b13a7671818971706faf208129",
        "name": "DomusAI",
        "symbol": "DOM",
        "decimals": 9,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x3e8efb432fb44b3c45d6d5286aa87805001e8791",
        "name": "MetaCene",
        "symbol": "MAK",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x8b0e6f19ee57089f7649a455d89d7bc6314d04e8",
        "name": "DMT",
        "symbol": "DMT",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x8b0e6f19ee57089f7649a455d89d7bc6314d04e8_large.png"
    },
    {
        "address": "0x99a3ff1aeb57581f53fe7c1e3b3ce52b1d03ff1a",
        "name": "Azra Games",
        "symbol": "AZRA",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xd77b108d4f6cefaa0cae9506a934e825becca46e",
        "name": "WINR",
        "symbol": "WINR",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xd77b108d4f6cefaa0cae9506a934e825becca46e_large.png"
    },
    {
        "address": "0x1c986661170c1834db49c3830130d4038eeeb866",
        "name": "Aperture",
        "symbol": "APTR",
        "decimals": 6,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x1c986661170c1834db49c3830130d4038eeeb866_large.png"
    },
    {
        "address": "0xd56734d7f9979dd94fae3d67c7e928234e71cd4c",
        "name": "TIA",
        "symbol": "TIA.n",
        "decimals": 6,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x51fc0f6660482ea73330e414efd7808811a57fa2",
        "name": "Premia",
        "symbol": "PREMIA",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x51fc0f6660482ea73330e414efd7808811a57fa2_large.png"
    },
    {
        "address": "0xc87b37a581ec3257b734886d9d3a581f5a9d056c",
        "name": "Aethir Token",
        "symbol": "ATH",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xc87b37a581ec3257b734886d9d3a581f5a9d056c_large.png"
    },
    {
        "address": "0x092baadb7def4c3981454dd9c0a0d7ff07bcfc86",
        "name": "MOR",
        "symbol": "MOR",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x092baadb7def4c3981454dd9c0a0d7ff07bcfc86_large.png"
    },
    {
        "address": "0x2c650dab03a59332e2e0c0c4a7f726913e5028c1",
        "name": "TapToken",
        "symbol": "TAP",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x2c650dab03a59332e2e0c0c4a7f726913e5028c1_large.png"
    },
    {
        "address": "0x7189fb5b6504bbff6a852b13b7b82a3c118fdc27",
        "name": "ether.fi governance token",
        "symbol": "ETHFI",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x498c620c7c91c6eba2e3cd5485383f41613b7eb6",
        "name": "Alongside Crypto Market Index",
        "symbol": "AMKT",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x498c620c7c91c6eba2e3cd5485383f41613b7eb6_large.png"
    },
    {
        "address": "0xdfb8be6f8c87f74295a87de951974362cedcfa30",
        "name": "EdgeMatrix Computing network",
        "symbol": "EMC",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xdfb8be6f8c87f74295a87de951974362cedcfa30_large.png"
    },
    {
        "address": "0x88a269df8fe7f53e590c561954c52fccc8ec0cfb",
        "name": "Ninja Squad Token",
        "symbol": "NST",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x88a269df8fe7f53e590c561954c52fccc8ec0cfb_large.png"
    },
    {
        "address": "0xe2e850adca6a53f5e8865ae794fd7cd4ca7c446c",
        "name": "Magic Vault 18",
        "symbol": "MagicVault18",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x7b1755a01bbb4b14627a4eaf1b9d20041b48092a",
        "name": "Sahara AI",
        "symbol": "SHR",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x10393c20975cf177a3513071bc110f7962cd67da",
        "name": "Jones DAO",
        "symbol": "JONES",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x10393c20975cf177a3513071bc110f7962cd67da_large.png"
    },
    {
        "address": "0xb299751b088336e165da313c33e3195b8c6663a6",
        "name": "StarHeroes",
        "symbol": "STAR",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xb299751b088336e165da313c33e3195b8c6663a6_large_3ee775af-61ae-4ae9-93a9-701b98dd9be7.png"
    },
    {
        "address": "0x3d9907f9a368ad0a51be60f7da3b97cf940982d8",
        "name": "Camelot token",
        "symbol": "GRAIL",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x3d9907f9a368ad0a51be60f7da3b97cf940982d8_large_af27f8a5-4e29-4f11-acde-290c5195a3a6.png"
    },
    {
        "address": "0xaed882f117b78034829e2cffa11206706837b1b1",
        "name": "Q Governance Token",
        "symbol": "WQ",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xb6093b61544572ab42a0e43af08abafd41bf25a6",
        "name": "WeatherXM",
        "symbol": "WXM",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xb6093b61544572ab42a0e43af08abafd41bf25a6_large.png"
    },
    {
        "address": "0x3124678d62d2aa1f615b54525310fbfda6dcf7ae",
        "name": "Sensay",
        "symbol": "SNSY",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x3124678d62d2aa1f615b54525310fbfda6dcf7ae_large.png"
    },
    {
        "address": "0x764bfc309090e7f93edce53e5befa374cdcb7b8e",
        "name": "GRIMACE COIN",
        "symbol": "GRIMACE",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x764bfc309090e7f93edce53e5befa374cdcb7b8e_large.png"
    },
    {
        "address": "0x7fe5e5befa99a57cd690f2e220c251c90422da8b",
        "name": "Kwenta",
        "symbol": "KWENTA",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x11f98c7e42a367dab4f200d2fdc460fb445ce9a8",
        "name": "SPARTA",
        "symbol": "SPARTA",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x11f98c7e42a367dab4f200d2fdc460fb445ce9a8_large.png"
    },
    {
        "address": "0xeeeeeb57642040be42185f49c52f7e9b38f8eeee",
        "name": "Elk",
        "symbol": "ELK",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xb688ba096b7bb75d7841e47163cd12d18b36a5bf",
        "name": "mPendle",
        "symbol": "mPendle",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xb688ba096b7bb75d7841e47163cd12d18b36a5bf_large.png"
    },
    {
        "address": "0xd44257dde89ca53f1471582f718632e690e46dc2",
        "name": "S",
        "symbol": "S",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x429fed88f10285e61b12bdf00848315fbdfcc341",
        "name": "THORWallet Governance Token",
        "symbol": "TGT",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x429fed88f10285e61b12bdf00848315fbdfcc341_large.png"
    },
    {
        "address": "0xec0e73bbb66a8413fdeeb11e40353aff09cffbca",
        "name": "AICAP",
        "symbol": "AICAP",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xa3d1a8deb97b111454b294e2324efad13a9d8396",
        "name": "OVN",
        "symbol": "OVN",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xa3d1a8deb97b111454b294e2324efad13a9d8396_large.png"
    },
    {
        "address": "0xc8a4eea31e9b6b61c406df013dd4fec76f21e279",
        "name": "Render Token",
        "symbol": "RNDR",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x13a7dedb7169a17be92b0e3c7c2315b46f4772b3",
        "name": "Boop",
        "symbol": "Boop",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x13a7dedb7169a17be92b0e3c7c2315b46f4772b3_large_200f3d5a-07c2-4ef8-9d05-bc2597f9987b.png"
    },
    {
        "address": "0xf5a27e55c748bcddbfea5477cb9ae924f0f7fd2e",
        "name": "Standard Token",
        "symbol": "TST",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xf5a27e55c748bcddbfea5477cb9ae924f0f7fd2e_large.png"
    },
    {
        "address": "0x000000000066ea454672f4733407a74b886d3c93",
        "name": "Burnt Hermes: Gov + Yield + Boost",
        "symbol": "bHERMES",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017",
        "name": "Radiant",
        "symbol": "RDNT",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xbc6d61f0fefe97555872ae4646ad9cd5068b4ae8",
        "name": "Seedworld",
        "symbol": "SWORLDT",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xb165a74407fe1e519d6bcbdec1ed3202b35a4140",
        "name": "Static Aave Arbitrum USDT",
        "symbol": "stataArbUSDT",
        "decimals": 6,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x7397d9cf4eb6122d51734075ff9f9cd985eb6a18",
        "name": "ARB-ETH-C",
        "symbol": "ARB-ETH-C",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x445a994cab4819d86e45cc3bbac72b3cf8381fc0",
        "name": "Ericaaaaa_desu",
        "symbol": "ERIC",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xd79bb960dc8a206806c3a428b31bca49934d18d7",
        "name": "Rosnet",
        "symbol": "ROSNET",
        "decimals": 9,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xd79bb960dc8a206806c3a428b31bca49934d18d7_large.png"
    },
    {
        "address": "0x43df266501dff4773f8f33179e3b96ab94dbc28d",
        "name": "Glend",
        "symbol": "GLEND",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x43df266501dff4773f8f33179e3b96ab94dbc28d_large.png"
    },
    {
        "address": "0xa14a26bb46e236da394da6b09a5b4cf737ce707b",
        "name": "Wrapped TAO",
        "symbol": "wTAO",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0x080f6aed32fc474dd5717105dba5ea57268f46eb",
        "name": "Synapse",
        "symbol": "SYN",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x080f6aed32fc474dd5717105dba5ea57268f46eb_large.png"
    },
    {
        "address": "0xe10d4a4255d2d35c9e23e2c4790e073046fbaf5c",
        "name": "LandX Governance Token",
        "symbol": "LNDX",
        "decimals": 6,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xe10d4a4255d2d35c9e23e2c4790e073046fbaf5c_large.png"
    },
    {
        "address": "0x8ef774fbd6e423465289a3c3b0dbc5b0c6200235",
        "name": "DOM Token",
        "symbol": "DOM",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    },
    {
        "address": "0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
        "name": "OX Coin",
        "symbol": "OX",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0xba0dda8762c24da9487f5fa026a9b64b695a07ea_large.png"
    },
    {
        "address": "0x59a729658e9245b0cf1f8cb9fb37945d2b06ea27",
        "name": "GenomesDAO",
        "symbol": "GENE",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": "https://crypto-token-logos-production.s3.us-west-2.amazonaws.com/42161_0x59a729658e9245b0cf1f8cb9fb37945d2b06ea27_large.png"
    },
    {
        "address": "0x2879e4cff83108042a3bd575b5ca46f5b230d073",
        "name": "Pamp Da Coin",
        "symbol": "PAMP",
        "decimals": 18,
        "chainId": 42161,
        "logoURI": null
    }
];

const getTokenNameFromAddress = (address: string): TokenAddress | undefined => {
    return tokenAddresses.find(token => token.address.toLowerCase() === address.toLowerCase());
}

export { tokenAddresses, getTokenNameFromAddress };