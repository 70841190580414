import React, { useState } from 'react';
import { Autocomplete, Grid, TextField, Button, Typography, Paper } from '@mui/material';
import { TokenAddress } from '../../types';
import { chains, tokenAddresses as allTokens } from '../../config';
import { useLoader } from '../../context/LoaderContext';
import { useWallet } from '../../context/WalletContext';
import { formatAddress, getSwapSmartContract, getTokenSmartContract } from '../../shared';
import useNotification from '../../utilities/notificationUtils';
import { handleMetaMaskError } from '../../utilities/handleMetaMaskError';
import * as ethers from "ethers";
import { useListing } from '../../context/ListingContext';

export const CreateListing: React.FC = () => {
    const [fromToken, setFromToken] = useState<TokenAddress | null>(null);
    const [toToken, setToToken] = useState<TokenAddress | null>(null);
    const [amountFrom, setAmountFrom] = useState<number>(0);
    const [amountTo, setAmountTo] = useState<number>(0);
    const { showLoader, hideLoader } = useLoader();
    const { showSuccess, showInfo, showError } = useNotification();
    const { isConnected, account, chainId, network } = useWallet();
    const { showEmoji } = useListing();

    const tokenAddresses = allTokens?.filter(x => x?.chainId === network);

    const handleFromTokenChange = (event: React.SyntheticEvent, value: TokenAddress | null) => {
        setFromToken(value);
    };

    const handleToTokenChange = (event: React.SyntheticEvent, value: TokenAddress | null) => {
        setToToken(value);
    };

    const handleAmountFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmountFrom(parseFloat(event.target.value) || 0);
    };

    const handleAmountToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmountTo(parseFloat(event.target.value) || 0);
    };

    const approveToken = async (tokenAddress: string, amount: number) => {
        const tokenContract = await getTokenSmartContract(tokenAddress);
        try {
            const amountFromInUnits = ethers.parseUnits(amount.toString(), fromToken?.decimals);
            const selectedSmartContract = chains.find(x => x.chainId === network)?.address ?? "";
            const tx = await tokenContract.approve(selectedSmartContract, amountFromInUnits);
            console.log('Approval transaction hash:', tx.hash);
            await tx.wait();
            console.log('Approval confirmed');
        } catch (error) {
            throw error;
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isConnected) {
            showError("Please connect your wallet to proceed");
            return;
        }
        if (!fromToken || !toToken) {
            showError("Please select both tokens");
            return;
        }
        showLoader();
        const contract = await getSwapSmartContract(network);
        try {
            const amountFromInUnits = ethers.parseUnits(amountFrom.toString(), fromToken.decimals);
            const amountToInUnits = ethers.parseUnits(amountTo.toString(), toToken.decimals);

            showInfo("Approve the transaction to continue");
            await approveToken(fromToken.address, amountFrom);
            showSuccess("Transaction approved, going to create the listing");

            const tx = await contract.createListing(
                account,
                fromToken.address,
                toToken.address,
                amountFromInUnits,
                amountToInUnits,
                false,
                chainId ?? 0, // default chain id
                false,
                0
            );
            console.log('Transaction hash:', tx.hash);
            await tx.wait();
            showSuccess("Listing created successfully");
            showEmoji();
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    return (
        <Paper className="container create-listing p-4 mt-4 mb-4" elevation={3}>
            <Typography className="note mt-1 mb-4" variant="h4" component="p">
                Create listing
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={tokenAddresses}
                            getOptionLabel={(option) => `${option.name} (${option.symbol}) ${formatAddress(option.address)}`}
                            value={fromToken}
                            onChange={handleFromTokenChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="From Token"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.logoURI ? (
                                        <img src={option.logoURI} alt={option.symbol} style={{ width: 30, height: 30, marginRight: 8 }} />
                                    ) : (
                                        <div
                                            style={{
                                                width: 30,
                                                height: 30,
                                                borderRadius: '50%',
                                                backgroundColor: '#ccc', // You can change this to a color you prefer
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8,
                                                fontSize: 14,
                                                color: '#fff',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {option.symbol.substring(0, 3)}
                                        </div>
                                    )}
                                    {option.name} ({option.symbol}) {formatAddress(option.address)}
                                </li>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="number"
                            label="Amount From"
                            variant="outlined"
                            fullWidth
                            value={amountFrom}
                            onChange={handleAmountFromChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={tokenAddresses}
                            getOptionLabel={(option) => `${option.name} (${option.symbol}) ${formatAddress(option.address)}`}
                            value={toToken}
                            onChange={handleToTokenChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="To Token"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.logoURI ? (
                                        <img src={option.logoURI} alt={option.symbol} style={{ width: 30, height: 30, marginRight: 8 }} />
                                    ) : (
                                        <div
                                            style={{
                                                width: 30,
                                                height: 30,
                                                borderRadius: '50%',
                                                backgroundColor: '#ccc', // You can change this to a color you prefer
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8,
                                                fontSize: 14,
                                                color: '#fff',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {option.symbol.substring(0, 3)}
                                        </div>
                                    )}
                                    {option.name} ({option.symbol}) {formatAddress(option.address)}
                                </li>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="number"
                            label="Amount To"
                            variant="outlined"
                            fullWidth
                            value={amountTo}
                            onChange={handleAmountToChange}
                        />
                    </Grid>
                </Grid>
                <Button type="submit" className="button mt-4" variant="contained">
                    Create
                </Button>
            </form>
            <Typography className="note mt-5" variant="body2" component="p">
                Please note each swap carries a 0.4% protocol fee
            </Typography>
        </Paper>
    );
};
