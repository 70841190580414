import * as ethers from "ethers";
import { chains, swapContractConfig, tokenContractConfig } from "../config";

const getTokenSmartContract = async (tokenAddress: string) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, tokenContractConfig.abi, signer);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

const getSwapSmartContract = async (network: number) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        const tokenContract = new ethers.Contract(chains.find(x => x.chainId === network)?.address ?? "", swapContractConfig.abi, signer);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

const getSwapSmartContractWithoutSigner = async (network: number) => {
    try {
        const chain = chains.find(x => x.chainId === network);
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        const tokenContract = new ethers.Contract(chain?.address ?? "", swapContractConfig.abi, ethersProvider.provider);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

export {
    getTokenSmartContract, getSwapSmartContract, getSwapSmartContractWithoutSigner
}