import { Chain } from "../types";

const chains: Chain[] = [
    // {
    //     name: "Sepolia",
    //     id: 1,
    //     chainId: 11155111,
    //     address: "0xB4a69A3622bBb894c2C8887c1a76e18107AA506D",
    //     rpc: "https://sepolia.infura.io/v3/7ed0997e17cf4e11a0e72153377dfa60",
    //     logoURI: "/sanko.png"
    // },
    // {
    //     name: "Holesky",
    //     id: 2,
    //     chainId: 17000,
    //     address: "0x07A8b895c7944A0dC8EC15E5FbDf25c13DbD5C07",
    //     rpc: "https://holesky.infura.io/v3/7ed0997e17cf4e11a0e72153377dfa60",
    //     logoURI: "/arbitrum.png"
    // },
    {
        name: "Sanko",
        id: 3,
        chainId: 1996,
        address: "0xD70AF668847d300b6e41196aCBc803d26c3baeD6",
        rpc: "https://mainnet.sanko.xyz",
        logoURI: "/sanko.png"
    },
    {
        name: "Arbitrum",
        id: 4,
        chainId: 42161,
        address: "0x312601e7cCf1643F88E887f992C5d045DB214fC6",
        rpc: "https://arb1.arbitrum.io/rpc",
        logoURI: "/arbitrum.png"
    },
];

enum ChainEnum {
    Sanko = 1996,
    Arbitrum = 42161
}

const getChain = (chainId: number): Chain | undefined => {
    return chains.find(x => x.chainId === chainId);
}

export { chains, getChain, ChainEnum };