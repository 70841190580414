import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import App from './App';
import SimpleBackdrop from './components/SimpleBackdrop';
import { LoaderProvider } from './context/LoaderContext';
import { WalletProvider } from './context/WalletContext';
import reportWebVitals from './reportWebVitals';
import { ListingProvider } from './context/ListingContext';

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#000000"
    }
  },
  typography: {
    fontFamily: 'MineSweeper, YoureGone, sans-serif'
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif' // Custom font family for ListItem
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <WalletProvider>
          <ListingProvider>
            <LoaderProvider>
              <SimpleBackdrop />
              <App />
            </LoaderProvider>
          </ListingProvider>
        </WalletProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
