import { Grid, IconButton, Typography } from '@mui/material';

import * as ethers from 'ethers';
import { useEffect, useState } from 'react';
import { Header, ListingCard, Navbar } from '../components';
import { ChainEnum, chains, getTokenNameFromAddress } from '../../config';
import { useLoader } from '../../context/LoaderContext';
import { useWallet } from '../../context/WalletContext';

import { getSwapSmartContractWithoutSigner } from '../../shared';
import { Listing } from '../../types';
import { handleMetaMaskError } from '../../utilities/handleMetaMaskError';
import useNotification from '../../utilities/notificationUtils';

export const Marketplace = () => {
    const [listings, setListings] = useState<Listing[]>([]);
    const { showLoader, hideLoader } = useLoader();
    const { showError } = useNotification();
    const { network } = useWallet();

    useEffect(() => {
        const fetchListings = async () => {
            showLoader();
            try {
                const contract = await getSwapSmartContractWithoutSigner(network);
                const rawData = await contract.getAllUnclaimedListings();
                console.log("Raw data from contract:", rawData);

                const [listingIds, unclaimedListings] = rawData;

                // Transform raw data to match the Listing interface
                const transformedListings: Listing[] = unclaimedListings.map((listing: any, index: number) => {

                    const fromTokenDecimals = getTokenNameFromAddress(listing.coinForSale)?.decimals;
                    const toTokenDecimals = getTokenNameFromAddress(listing.coinToReceive)?.decimals;

                    return {
                        listingId: Number(listingIds[index].toString()),
                        seller: listing.seller,
                        coinForSale: listing.coinForSale,
                        coinToReceive: listing.coinToReceive,
                        amountForSale: ethers.formatUnits(listing.amountForSale.toString(), fromTokenDecimals),
                        amountToReceive: ethers.formatUnits(listing.amountToReceive.toString(), toTokenDecimals),
                        isCrossChain: listing.isCrossChain,
                        destinationChainId: Number(listing.destinationChainId.toString())
                    }
                });

                setListings(transformedListings.reverse());
            } catch (error) {
                console.error('Error fetching listings:', error);
                showError(handleMetaMaskError(error));
            } finally {
                hideLoader();
            }
        };

        fetchListings();
    }, [network]);

    return (
        <>
            <Navbar />

            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <div className="content">
                        <div className="logo d-flex flex-wrap">
                            <div className="brick-row">
                                <span className="brick-letter blue-brick-letter">O</span>
                                <span className="brick-letter green-brick-letter">V</span>
                                <span className="brick-letter black-brick-letter">E</span>
                                <span className="brick-letter red-brick-letter">R</span>
                            </div>
                            <span className="brick-letter logo-brick-spacing"> </span>
                            <div className="brick-row">
                                <span className="brick-letter red-brick-letter">T</span>
                                <span className="brick-letter green-brick-letter">H</span>
                                <span className="brick-letter blue-brick-letter">E</span>
                            </div>
                            <span className="brick-letter logo-brick-spacing"> </span>
                            <div className="brick-row">
                                <span className="brick-letter blue-brick-letter">C</span>
                                <span className="brick-letter green-brick-letter">O</span>
                                <span className="brick-letter black-brick-letter">U</span>
                                <span className="brick-letter red-brick-letter">N</span>
                                <span className="brick-letter black-brick-letter">T</span>
                                <span className="brick-letter green-brick-letter">E</span>
                                <span className="brick-letter blue-brick-letter">R</span>
                            </div>
                        </div>

                        <div>
                            <span className="brick-letter"></span>
                        </div>
                        {
                            listings.length === 0 && <Typography variant="body1">
                                <div className="d-flex flex-wrap">
                                    {"No listings available".split('').map((char, index) => {
                                        // Check if the character is a space, and if so, render a space brick
                                        return char === ' ' ? (
                                            <span key={index} className="brick-letter"></span>
                                        ) : (
                                            <span key={index} className="brick-letter">{char}</span>
                                        );
                                    })}
                                </div>
                            </Typography>
                        }
                        <Grid container spacing={4} className='p-2'>
                            {listings.reverse().map((listing, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <ListingCard
                                        listing={listing}
                                        onRemoved={() => {
                                            setListings(listings.filter(e => e.listingId !== listing.listingId));
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
};
